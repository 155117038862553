import React from 'react'
import { Col, Container, Row } from 'react-bootstrap';
import image from "../../../assets/frontend.webp"

const Frontend = () => {
  return (
    <section className='services' id='services'>
      <Container>
        <Row><h2>Front End Eğitimi</h2>
          <Col md="5">
              <img src={image} alt='contact' />
          </Col>
          <Col md="7">
              <h4>Eğitimlerimiz özenle hazırlanmış olup içerik açısından zengin ve deneyimli eğitmenlerimiz tarafından verilmektedir.
                6 ay sürecek olan front end eğitiminiz boyunca ve sonrasında da aklınıza takılan her türlü soruyu rahatça sorabilir, hayalinizdeki projeyi hayata
                 geçirmek için eğitmenlerimizden destek alabilir ve eğitiminizi tamamladıktan sonra bu alanda kolaylıkla iş 
                 bulabilirsiniz. Eğitimini başarıyla tamamlayan öğrencilerimiz E-devlet onaylı sertifika alacak
                ve kurumumuz tarafından katılım sertifikası alacaktır. Eğitiminiz bitiminden sonra 4 haftalık bir staj 
                süreciniz olacaktır. Staj sürecini başarı ile tamamlayan öğrencilerimiz bizimle çalışma fırsatı bulacaktır.
              </h4>
          </Col>
          <Col>
          <h3>Eğitim Müfredatı</h3>
          <ul className='text-center fs-4' >
            <li>HTML5</li>
            <li>CSS3</li>
            <li>SASS</li>
            <li>BOOTSTRAP</li>
            <li>JAVASCRİPT</li>
            <li>JQUERY</li>
            <li>REACT JS</li>
            <li>GİT - GİTHUB</li>
          </ul>
          <h4>Ayrıntılı bilgi için bizimle WhatsApp iletişim hattımızdan destek alabilirsiniz.</h4>
          </Col>
     
        </Row>
      </Container>
      
    </section>
  )
}

export default Frontend
