import React, { useRef, useState, useEffect } from 'react'
import emailjs from '@emailjs/browser';
import { Col, Container, Row } from 'react-bootstrap';
import './style.css'
import mail from '../../assets/mail.webp'

const Contact = () => {
  const form = useRef();
  const [status, setStatus] = useState('');

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_6iaw37j', 'template_3x2301z', form.current, 'iGSGaypjNlQNoN57E')
      .then((result) => {
          console.log(result.text);
          setStatus('SUCCESS');
      }, (error) => {
          console.log(error.text);
      });
      e.target.reset();
  };

  useEffect(() => {
    if(status === 'SUCCESS') {
      setTimeout(() => {
        setStatus('');
      }, 3000);
    }
  }, [status]);

  return (
    <section className='contact' id='contact'>
      <Container>
        <Row className='align-items-center'>
          <Col md={6}>
            <img src={mail} alt='contact' />
          </Col>
          <Col md={6}>
            <h2>Bize Ulaşın</h2>
            {status && Alert()}
            <form ref={form} onSubmit={sendEmail}>
              <Row>
                <Col sm={6} className="px-1">
                  <input type='text' placeholder='İsim*' name='user_first_name' required  />
                </Col>
                <Col sm={6} className="px-1">
                  <input type='text'  placeholder='Soyisim*' name='user_last_name'  required  />
                </Col>
                <Col sm={6} className="px-1">
                  <input type='email'  placeholder='Email*' name='user_email'  required/>
                </Col>
                <Col sm={6} className="px-1">
                  <input type='tel'  placeholder='Telefon*' name='user_phone'  />
                </Col>
                <Col>
                  <textarea row='6'  placeholder='Mesaj*' name='message' required />
                  <button type='submit' value='Send'> <span>Gönder</span> </button>
                </Col>
              </Row>
            </form>
          </Col>
        </Row>
      </Container>
    </section>
  )
}

const Alert = () => (
  <div className='text-success px-1'>
    <p>Your message successfully!</p>
  </div>
)

export default Contact