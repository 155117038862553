import React from 'react' 
import { Container } from 'react-bootstrap'
import './style.css'

const Policy = () => { 
  return (
    <div className='policy'>
      <Container>
        <h1 className='text-center'>Gizlilik ve Çerez Politikası</h1>
        <h3>Genel</h3>
        <p>Rise of Software yazılım şirketi olarak, 
          web sitesini ziyaret edenlerin gizliliğini korumayı ilke olarak benimsedik. </p>
        <p>Rise of Software yazılım şirketi olarak, kişisel verilerin hukuka uygun işlenmesi adına 6698 
          sayılı Kişisel Verilerin Korunması Kanunu hükümlerine, Kişisel Verileri Koruma Kurulu kararlarına
          ve uygun düştüğü ölçüde Avrupa Birliği Veri Koruma Tüzüğü hükümlerine uyum sağlamak
          için gerekli her türlü tedbir ve aksiyonu alıyoruz. </p>
        <p>Sizleri, kullanıcılara ilişkin ne tür bilgilerin Rise of Software yazılım şirketi tarafından işlendiğini 
          ve bunların nasıl kullanıldığı konusunda bilgilendirmek istiyoruz. Aynı zamanda sizleri, veri sahibi olarak ilgili 
          haklarınızı nasıl kullanabileceğinize dair bilgilendireceğiz.</p>
        <p>İşbu Gizlilik ve Çerez Politikası‘na ilişkin olası değişiklikler bu sayfada yayımlanacaktır. Bu çerçevede, hangi 
          bilgilerin saklandığına ve bu bilgilerin nasıl kullanıldığına dair güncel olarak bilgi almanıza olanak sağlanacaktır.</p>
        <p>İşbu Gizlilik ve Çerez Politikası , Rise of Software yazılım şirketinin sitesinden bağlantı uzantısı verilmiş başka web siteleri için geçerli değildir.</p>
        <h3>Kişisel verilerin toplanması, kullanımı ve işlenmesi</h3>
        <p>Sorumlu kuruluşun adı: Rise of Software yazılım şirketi</p>
        <p>Rise of Software yazılım şirketi, ilgili kişisel verileri bu web sitesini kullanıcılara sunmak ve bu sitenin uygun şekilde çalıştığının ve gerekli ölçüde güvenliğin sağlandığının
          temin edilmesi doğrultusunda kullanır. Bu kapsam dışında kalan ve veriler üzerinde gerçekleştirilen her işlem, 
          diğer yasal yükümlülükler, zinler, Rise of Software yazılım şirketinin meşru menfaatinin bulunduğu haller zemininde ya da ilgili kullanıcının 
          Rise of Software yazılım şirketine verdiği açık rıza çerçevesinde gerçekleşmektedir. </p>
        <p>Rise of Software yazılım şirketi, ilgili verileri özel olarak şu amaçlar doğrultusunda saklar ve işler:</p>
        <p>Kullanıcılar web sitesini ziyaret ettiğinde Rise of Software yazılım şirketi belirli verileri otomatik olarak toplar ve saklar. Bu verilere:
          Talep edilen içeriği (örn. özellikle içeriği, metinleri, görselleri ve indirme için sağlanan veri dosyalarını vb.) 
          iletebilmek için gerek duyulan, ilgili uç cihaza tahsis edilmiş IP adresi ya da cihaz kimliği, kullanıcıların web 
          sitesi bağlamındaki aktiviteleri, ilgili uç cihaz türü, kullanılan tarayıcı türü ve kullanım tarihi ve saati dahildir.</p>
        <p>Rise of Software yazılım şirketi bu bilgileri amaç dışında kullanımı tespit ve takip edebilmek üzere 7 gün süreyle saklamaktadır.</p>
        <p>Aynı zamanda Rise of Software yazılım şirketi, hizmetlerin sunumunu, özelliklerini, işlevlerini ve genel yönetim görevlerini geliştirmek için de bu bilgileri kullanır.</p>
        <p>Bunların yanında Rise of Software yazılım şirketi, yukarıda verilen amaçlar doğrultusunda söz konusu verilere artık gerek duyulmadığında, IP adresleri de dahil olmak üzere kullanımdaki verileri usule aykırı gecikme olmaksızın siler ya da anonim hale getirir.</p>
        <p>İlgili verilerin işlenmesi ve kullanımı, işlemenin web sitesinin sunulabilmesi için gerekli olduğu; ya da Rise of Software yazılım şirketinin, web sitesinin işlevselliğini ve sorunsuz
          biçimde çalışmasını, aynı zamanda da kullanıcıların gereksinimlerine uygun olmasını temin eden ve geliştiren meşru 
          bir menfaate sahip olduğu durumlarda, bu işlemlerin yapılabilmesine imkan sağlayan ilgili yasa hükümlerinin
          oluşturduğu zemin doğrultusunda gerçekleştirilmektedir.</p>
        <p>Toplanan verilerin alıcılarına; sorumlu bulunan kurum içi departmanların üyeleri, Rise of Software yazılım şirketine bağlı diğer şirketler, dış hizmet sağlayıcıları
          (örn. hosting ve içerik yönetimi, pazarlama ajansları, sağladıkları hizmetler dolayısıyla gerekli olması halinde diğer üçüncü taraf
          sağlayıcılar), ilgili yükümlülükleri ve yetkileri çerçevesinde; örneğin, resmi bir talepte bulunulması ya da
          Rise of Software yazılım şirketinin sahip olduğu hakları tespit etmek, uygulamak ya da savunmak üzere gerekli olması halinde ilgili yetkililer ve 
          Rise of Software yazılım şirketinin mevcut hissedarları ya da satış, birleşme ya da satın alma durumunda gelecekteki hissedarları dahil olabilir.</p>
        <h3>Kişisel Verilerin İşlenme Amaçları</h3>
        <p>İletişim bilgilerinizi web sitemizde yer alan “İletişim” isimli iletişim formu üzerinden veya ilgili sekmede yer alan çağrı merkezi numaralarımız aracılığıyla bizimle paylaşmış olabilirsiniz.</p>
        <p>Size ait verileri talebinize cevap vermek amacıyla kullanacağız.</p>
        <p>Verilerinizin işlenmesi ve kullanımı, mevcut talebinizi karşılamak için işlemenin gerekli olması halinde bunun yapılabilmesine imkan sağlayan yasa hükümlerinin ve/veya Rise of Software yazılım şirketinin meşru menfaatinin 
          oluşturduğu zemin doğrultusunda gerçekleştirilmektedir.</p>
        <p>Herhangi bir açık rıza beyanı vasıtasıyla daha uzun bir süre için onay vermediğiniz durumda, verileri yalnızca yukarıda verilen hedefi gerçekleştirmek için gerekli süre ya da herhangi bir yasal saklama zorunluluğunun gerektirdiği dönem boyunca saklayacağız.</p>
        <h3>Çerezler, Pikseller, ve benzer teknolojiler</h3>
        <p>Bu web sitesi, çerezleri ve benzeri teknolojileri kullanmaktadır. Çerezler, web sitemizi kullanmak için 
          gerekli olan ve tarayıcınız tarafından sabit diskinize geçici olarak depolanan küçük veri birimleridir. 
          Bir çerez genellikle, rastgele şekilde oluşturulmuş bir sayıdan oluşan ve cihazınıza depolanan benzersiz 
          bir tanımlayıcı içerir. Bu tanımlayıcıların bazıları web sitesi oturumunuz sona erdiğinde geçerliliğini 
          yitirirken, bazıları ise daha uzun süreler boyunca cihazınızda kalabilir. Teknik açıdan gerekli çerezler 
          otomatik olarak kullanılacaktır. Diğer çerezler (ya da benzeri teknolojiler) yalnızca ön onayınız üzerine 
          uygulanacaktır.</p>
        <p>Farklı türde çerezler bulunmaktadır. Birinci taraf çerezleri, ziyaret edilen web sitesi tarafından yerleştirilir 
          ve yalnızca o web sitesi tarafından okunabilir. Üçüncü taraf çerezleri ise, kullandığımız çeşitli servisler için 
          hizmet aldığımız diğer kuruluşlar tarafından yerleştirilmektedir. Buna örnek olarak, web sitemizde beğenilen ya da 
          beğenilmeyen içerikleri tespit edebilmek amacıyla bizim adımıza çerezler yerleştiren harici analitik hizmeti 
          sağlayıcıları kullanıyoruz. Buna ek olarak, ziyaret ettiğiniz web sitesi, örneğin, YouTube gibi bir kaynaktan 
          alınmış gömülü bir içerik barındırabilir ve çerezler bu kaynak tarafından da yerleştirilebilir.</p>
        <p>Bir piksel genellikle, içerik kişiselleştirme ya da tarayıcı deneyimini daha etkili ve kolay hâle getirme amacı 
          doğrultusunda bir web sitesine yerleştirilen ve IP adresi, tarayıcı türü, erişim zamanı ya da önceden ayarlanan 
          çerezler gibi belirli verileri toplayan yalnızca 1 piksel x 1 piksel ölçülerindeki şeffaf bir görseldir. 
          Piksellerin çerezleri oluşturup yönetmesi nedeniyle sıklıkla çerezlerle birlikte kullanılırlar.</p>
        <p>Web sitesi, onay vermeniz halinde cihazın işletim sistemi, tarayıcısı, yüklü yazı tipleri, IP adresi, 
          eklentileri ve daha detaylı bilgileri toplayacaktır. Bu sayede cihaz, kullanıcıya ilişkin pazarlama amaçları 
          doğrultusunda yeniden tanınabilir.</p>
        <p>Çerezler ve web sitemizde kullanılan diğer teknolojiler ve bunların kullanım amaçları hakkında daha 
          detaylı bilgi almak için web sitemizde yer alan “Çerezler” ve “Çerez ayarları” bölümlerine göz atabilirsiniz. 
          Aynı zamanda bu bölümler içerisinden, ilgili çerez ayarlarını devre dışı bırakarak mevcut onaylarınızı bundan 
          böyle geçerli olmak üzere geri çekebilirsiniz.</p>    
      </Container>
    </div>
    ) 
  } 
export default Policy
