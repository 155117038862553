import React from 'react' 
import { Container } from 'react-bootstrap'
import './style.css'

const Mesafelisatis = () => { 
  return (
    <div className='mesafelisatis'>
      <Container>
        <h1 className='text-center'>MESAFELİ SATIŞ SÖZLEŞMESİNDEN ÖNCE TÜKETİCİYE VERİLMESİ GEREKEN ZORUNLU ÖN BİLGİ FORMU</h1>
        <h3>1.	Sağlayıcı Bilgileri:</h3>
        <p>Unvan :Rise Of Software Yazılım Şirketi 
            <br/><br/>
            Adres : 7418 sok. no:1 Süleyman Bey Apt. Karşıyaka/İZMİR
            <br/><br/>
            Tel : 0501 083 93 00
            <br/><br/>
            E-Posta Adresi: info@riseofsoftware.com
        </p>
        <h3>2.	Talep ve Şikâyetlerin İletileceği Kişiler: Süleyman Özdamar, Öznur Özen</h3>
        <p>İletişim Bilgileri:
        <br/><br/>
        Telefon: 0501 083 93 00
        <br/><br/>
        E-posta Adresi:suleymanozdamar93@gmail.com
        <br/><br/>
        E-posta Adresi:oznurozen67@gmail.com
        </p>
        <br/>
        <h3>3.	Sözleşme Konusu Hizmetin Temel Özellikleri:<br/></h3>
           <h4> 3.1. Eğitim Programı:</h4>
            <h4>3.2. Portal Kullanım Başlangıç Tarihi:</h4>
           <h4>3.3. Kullanım Süresi:</h4> 
           <br/> <br/>
        
        <h3>4.	Tüm Vergiler Dâhil Satış Fiyatı
        </h3>
        <p>Tüm Vergiler Dâhil Hizmetin Peşin Satış Fiyatı :</p>
        <br/>
        <h3>5.	Ödemenin Nasıl Yapılacağı:
        </h3>
        <p>İşbu sözleşme kapsamında hizmet/eğitim için ödeme Kredi Kartı/EFT/Havale ile yapılabilir.</p>
        <br/>  <br/>

        <h3>6.	SERTİFİKA ve STAJ
        </h3>
        <p>6.1.  HİZMET VEREN sözleşme kapsamında verilecek olan eğitim hizmetinin sonunda 
        HİZMET ALAN’ın başarılı olması halinde HİZMET ALAN’a sertifika vermeyi kabul 
        ve taahhüt eder. Eğitim sonunda HİZMET ALAN’ın HİZMET VEREN tarafından 
        verilen katılım sertifikası edinmesi için canlı eğitimlere en az yüzde 70 katılım 
        sağlaması veya 100 üzerinden en az 70 puan alması, üniversite onaylı sertifika 
        için canlı eğitimlere en az yüzde 80 katılım sağlaması veya 100 üzerinden en az 
        80 puan alması gerekmektedir. Üniversite onaylı sertifikada üniversite, HİZMET ALAN’ın mezun 
        olduğu  süreçte  HİZMET  VEREN’in  anlaşmalı  olduğu  üniversiteye  bağlı  olarak 
        değişiklik gösterebilir. 
        <br/>  <br/>
        6.2.  HİZMET ALAN’ın sektörel staj eğitim  sürecinde yer alması için en az yüzde 90 
        katılım ve 90 üzeri not ortalamasına sahip olması gerekir. Sektörel staj eğitim 
        süreci  HİZMET  VEREN  tarafından  HİZMET  ALAN’  a  sunulan  bir  imkândır.
        Sözleşme feshi oluşturan durumlarda iade edilecek tutar hesaplanırken sektörel 
        staj süreci saati bu hesaplamaya dâhil edilmez.
        </p>

        <h3>7.	Cayma Hakkı
        </h3>
        <p>Sözleşmeden cayma süresi, sözleşmenin imzalandığı andan itibaren başlar ve on dört (14) gündür. Kullanıcı, bu süre zarfında hiçbir hukuki ve cezai sorumluluk üstlenmeksizin ve hiçbir gerekçe göstermeksizin sözleşmeden cayma hakkına sahiptir. Cayma beyanının sağlayıcı Rise Of Software’a  ulaştırıldığını ispat yükümlüğü Kullanıcıya aittir. Bu süre geçtikten sonra yapılacak sözleşmenin feshi talepleri, sözleşme hükümlerine göre sonuçlandırılacaktır. <br/> <br/>
Size sağlanacak olan hizmet “Eğitim Hizmeti” olduğu için onaylayacağınız sözleşme, Mesafeli Sözleşmeler Yönetmeliği’nin “Cayma Hakkının İstisnaları” alt başlıklı 15. maddesinde düzenlenen, “Elektronik ortamda anında ifa edilen hizmetler veya tüketiciye anında teslim edilen gayri maddi mallara ilişkin sözleşmeler. Cayma hakkı süresi sona ermeden önce, tüketicinin onayı ile ifasına başlanan hizmetlere ilişkin” sözleşmelerdendir ve cayma hakkının istisnası kapsamındadır.<br/> <br/>
Bu nedenle 14 günlük cayma süresi içerisinde hizmetin verilmeye başlanması halinde, cayma hakkınızı en geç hizmetin başladığı günden bir gün önce bildirmiş olmanız gerekmektedir. Aksi takdirde sözleşmenin feshine dair talepleriniz, sözleşmede yazılı olan fesih hükümlerine göre sonuçlandırılacaktır.
</p>
        <br/>  <br/>
        <h4><u>Cayma Hakkı Yapılacak İletişim Bilgileri</u></h4>
        <p>Unvan : Rise Of Software Yazılım Şirketi<br/>  <br/>
Adres : 7418 sok. no:1 Süleyman Bey Apart. Karşıyaka/İZMİR<br/>  <br/>
E-posta Adresi : info@riseofsoftware.com<br/>  <br/>
İletişim Bilgisi : 0501 083 93 00<br/>  <br/>
7.	Bu sözleşmeden doğabilecek uyuşmazlıklarda, Gümrük ve Ticaret Bakanlığı’nca yasa gereği her yıl belirlenen-ilan edilen parasal sınırlar dâhilinde İl ve İlçe Tüketici Hakem Heyetleri, bu sınırları aşan durumlarda Tüketici Mahkemeleri görevli-yetkilidir. Kullanıcı, bu çerçevede, kendisinin veya dilerse sağlayıcının yerleşim yerindeki ( ikametgâhındaki) Hakem Heyetleri ve Tüketici Mahkemeleri’ne başvurabilir.
İade Prosedürü: Kullanıcının işbu sözleşme kapsamındaki içeriğin verilememesi, kusurlu verilmesi vs. gibi gerekçeler ile cayma hakkını kullandığı durumlarda ya da Tüketici Sorunları Hakem Heyeti kararları ile Kullanıcıya bedel iadesine karar verilen durumlarda, alışveriş kredi kartı ile tek çekim veya taksitli olarak yapılmışsa kredi kartına iade prosedürü banka tarafından belirlenecektir. Kullanıcı, ilgili banka tarafından uygulanacak iade prosedürünü peşinen kabul ettiğini kabul ve taahhüt eder.
</p>
      </Container>
    </div>
    ) 
  } 
export default Mesafelisatis
