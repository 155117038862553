import React from 'react';
import {
  MDBFooter,
  MDBContainer,
  MDBCol,
  MDBRow,
} from 'mdb-react-ui-kit';
import logo from '../../assets/logo.webp'
import { BsTwitter } from "react-icons/bs";
import { BsInstagram } from "react-icons/bs";
import { FaLinkedinIn } from "react-icons/fa";
import { FaYoutube } from "react-icons/fa";
import { FaFacebook } from "react-icons/fa";
import { MdEmail } from "react-icons/md";
import { MdPhone } from "react-icons/md";
import { Link } from 'react-router-dom';
import './style.css'


export default function App() {
  return (
    <MDBFooter className='bg-black text-white text-center'>
      <MDBContainer className='p-4'>
        <MDBRow>
          <MDBCol lg="4" md="12" className='mb-4 mb-md-0'>
          <img src={logo} alt='logo' className='logo' />
          <div>
            <h5>E-mail</h5>
                <MdEmail className='me-2' />
                info@riseofsoftware.com
                
                <h5 className='mt-3'>Telefon</h5>
                <MdPhone className='me-2' />
                +90501 083 93 00
          </div>
          </MDBCol>

          <MDBCol lg="4" md="6" className='mb-4 mb-md-0 '>
          <h3 className='mb-3 mt-3'>Site Haritası</h3>
            <ul className='list-unstyled mb-0'>
              <li>
                <Link className='nav-link' to='/'>Ana Sayfa</Link>
              </li>
              <li>
                <Link className='nav-link' to='/about'>Hakkımızda</Link>
              </li>
              <li>
                <Link className='nav-link' to='/egitim'>Eğitimler</Link>
              </li>
              <li>
                <Link className='nav-link' to='/services'>Hizmetlerimiz</Link>
              </li>
              <li>
                <Link className='nav-link' to='/contact'>İletişim</Link>
              </li>
              <li>
                <Link className='nav-link' to='/policy'>Gizlilik Politikası</Link>
              </li>
              <li>
                <Link className='nav-link' to='/mesafelisatis'>Mesafeli Satış Sözleşmesi</Link>
              </li>
            </ul>
          </MDBCol>

          <MDBCol lg="4" md="6" className='mb-4 mb-md-0'>
          <div className='social-icon footer-icon col-12'>
                <h3 className='mb-3 mt-3'>Sosyal Medya</h3>
                <a href="https://www.instagram.com/riseofsoftware"> <BsInstagram className='icons' /></a>
                <a href="https://twitter.com/riseofsoftware"> <BsTwitter className='icons' /></a>
                <a href="https://www.linkedin.com/company/rise-of-software/?viewAsMember"> <FaLinkedinIn className='icons' /></a>
                <a href="https://www.youtube.com/@riseofsoftware"> <FaYoutube className='icons' /></a>
                <a href="https://www.facebook.com/riseofsoftware/"> <FaFacebook className='icons' /> </a>
           </div>
          </MDBCol>
        </MDBRow>
      </MDBContainer>

      <div className='text-center p-3' style={{ backgroundColor: 'rgba(0, 0, 0, 0.2)' }}>
        <div className='my-4 w-75 m-auto'>
            <hr />
        </div>
        <p className=''>
            All Rights Reserved  &copy; CopyRight 2023. <br/> Rise of Software
        </p>
      </div>
      
    </MDBFooter>
  );
}