import { useEffect, useState } from 'react'
import { Navbar, Container, Nav } from 'react-bootstrap'
import { BsTwitter } from "react-icons/bs";
import { BsInstagram } from "react-icons/bs";
import { FaLinkedinIn } from "react-icons/fa";
import { Link } from 'react-router-dom';
import logo from '../../assets/logo.webp';
import './style.css';

const NavBar = () => {
    const [activeLink, setActiveLink] = useState('home');
    const [scrolled, setScrolled] = useState(false);
    const [show, setShow] = useState(false);

    useEffect(() => {
        const onScroll = () => {
            if (window.scrollY > 50){
               setScrolled(true); 
            } else {
                setScrolled(false);
            }
        }
        window.addEventListener("scroll", onScroll);

        return () => window.removeEventListener("scroll", onScroll);
    }, [])

    const onUpdateActiveLink = (value) => {
        setActiveLink(value);
    }

  return (
    <Navbar expand="lg" className={scrolled ? "scrolled" : ""} id='mobil'>
      <Container>
        <Navbar.Brand href="/" className='logo'>
            <img src={logo} alt="logo" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav">
            <span className='navbar-toggler-icon' onClick={() => setShow(!show)}></span>
        </Navbar.Toggle>
        <Navbar.Collapse id="basic-navbar-nav" className={show ? 'show' : "hide"}>
          <Nav className="me-auto" onClick={() => setShow(!show)}>
            <Nav.Link as={Link} to="/" className={activeLink === 'home' ? 'active navbar-link' : 'navbar-link'} onClick={() => onUpdateActiveLink('home') } >Ana Sayfa</Nav.Link>
            <Nav.Link as={Link} to="/about" className={activeLink === 'about' ? 'active navbar-link' : 'navbar-link'} onClick={() => onUpdateActiveLink('about') } >Hakkımızda</Nav.Link>
            <Nav.Link as={Link} to="/services" className={activeLink === 'services' ? 'active navbar-link' : 'navbar-link'} onClick={() => onUpdateActiveLink('services') } >Hizmetlerimiz</Nav.Link>
            <Nav.Link as={Link} to="/egitim" className={activeLink === 'egitim' ? 'active navbar-link' : 'navbar-link'} onClick={() => onUpdateActiveLink('egitim') } >Eğitimler</Nav.Link>
          </Nav>
          <span className='navbar-text' onClick={() => setShow(!show)}>
            <div className='social-icon'>
            <a href="https://www.instagram.com/riseofsoftware"> <BsInstagram className='icons' /> </a>
                <a href="https://twitter.com/riseofsoftware"> <BsTwitter className='icons' /> </a>
                <a href="https://www.linkedin.com/company/rise-of-software/?viewAsMember"> <FaLinkedinIn className='icons' /> </a>
            </div>
            <Link className='connect' to='/contact'>
              <button className='vvd' onClick={() => setShow(!show)}>
              
                <span>Bize Ulaşın</span>
              
              </button>
            </Link>
          </span>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  )
}

export default NavBar