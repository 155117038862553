import './App.css';
import NavBar from './components/Navbar';
import 'bootstrap/dist/css/bootstrap.min.css';
import Banner from './components/Banner';
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import About from './components/About';
import Services from './components/Services';
import Contact from './components/Contact';
import Footer from './components/Footer';
import Policy from './components/Policy';
import Egitim from './components/Egitim';
import Whatsapp from './components/Whatsapp';
import Frontend from './components/Egitim/Frontend';
import Backend from './components/Egitim/Backend';
import FullStack from './components/Egitim/FullStack';
import Mesafelisatis from './components/Mesafelisatis';



function App() {
  return (
    <BrowserRouter>
      <div className="App">
        <Whatsapp/>
        <NavBar />
        <div className='appClass'>
          <Routes>
            <Route exact path='/' element={ <Banner />} />
            <Route path='/contact' title="iletisim" element={ <Contact /> }/>
            <Route path='/about' title="hakkimizda" element={ <About /> }/>
            <Route path='/services' title="hizmetlerimiz" element={ <Services /> }/>
            <Route path='/egitim' title="Eğitimler" element={ <Egitim /> }/>
            <Route path='/policy' title="gizlilikpolitikasi" element={ <Policy /> }/>
            <Route path='/mesafelisatis' title="mesafelisatissözlesmesi" element={ <Mesafelisatis /> }/>
            <Route path='/frontend' title="frontendegitimi" element={ <Frontend /> }/>
            <Route path='/backend' title="backendegitimi" element={ <Backend /> }/>
            <Route path='/fullstack' title="mobileegitimi" element={ <FullStack /> }/>
          </Routes>
        </div>
        <Footer />
      </div>
    </BrowserRouter>
  );
}

export default App;
