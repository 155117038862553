import React from 'react'
import './style.css';

import  FaWhatsappp  from "../../assets/whatsapp.png";

const Whatsapp = () => {
  return (
    <div className='wp'>
        <a href="https://wa.me/905010839300" class="btn-whatsapp-pulse">
           <img className='wpicon' src={ FaWhatsappp } alt="wp"/>
        </a>
    </div>
  )
}

export default Whatsapp
