import { Col } from "react-bootstrap"
import { Link } from "react-router-dom"
import './style.css'

export const Card1 = ({ title, description, imgUrl, to }) => {
    return (
      <Col size={12} sm={6} md={4}>
        <Link to={to}>
          <div className="card1-imgbx">
          <img src={imgUrl} alt="#" />
          <div className="card1-txtx">
            <h4>{title}</h4>
            <p  className="description">{description}</p>
          </div>
        </div>
        </Link>
      </Col>
    )
  }