import React from 'react'
import { Col, Container, Row, Tab, Nav } from 'react-bootstrap';
import './style.css'
import TrackVisibility from 'react-on-screen';
import 'animate.css';

const About = () => {
  

  return (
    <section className='about' id='about'>
      <Container>
        <Row>
          <Col size={12}>
            <TrackVisibility>
              {({ isVisible }) =>
              <div className={isVisible ? "animate__animated animate__fadeIn" : ""}>
                <h2>Hakkımızda</h2>
                <Tab.Container id='about-tabs' defaultActiveKey='first'>
                <Nav variant="pills" className='nav-pills mb-5 justify-content-center align-items-center' id='pills-tab' >
                  <Nav.Item>
                    <Nav.Link eventKey="first" className='col-12'>
                      Biz Kimiz
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="second">
                      Vizyonumuz
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="third">
                      Misyonumuz
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
                <Tab.Content id="slideInUp" className={isVisible ? "animate__animated animate__slideInUp" : ""}>
                  <Tab.Pane className='tabPanel' eventKey='first'>
                    <p>
                      2022 yılının eylül ayında iki ortak olarak bir websitesi açmaya karar verdik.
                      Bu sayede hayallerimizi gerçekleştirmek için ilk adımı atmış olduk.
                    </p>
                    <p>
                    Rise Of Software yazılım şirketi, müşterilerine en iyi kalitede yazılım çözümleri 
                    sunmak amacıyla kurulmuş bir şirkettir. Sektöründe lider bir konuma sahip olan şirketimiz,
                     müşteri memnuniyetini en üst düzeye çıkarmak ve onların işlerini kolaylaştırmak için sürekli 
                     olarak yenilikçi çözümler geliştiriyor. Ayrıca çalışanlarımızın kişisel gelişimine yatırım 
                     yaparak, onların motivasyonlarını artırır ve onların yeteneklerini kullanmalarına yardımcı 
                     olur. Bu sayede müşterilerimize en iyi hizmeti veren bir şirket olma misyonunu gerçekleştirmeyi 
                     hedefliyoruz.
                    </p>
                    <p>
                    Rise Of Software yazılım şirketi, profesyonel ekibi ve geniş yelpazedeki müşteri portföyü ile 
                    sektöründe lider bir konumdadır. Müşterilerimiz arasında küçük ve orta ölçekli işletmelerden büyük 
                    kurumlara kadar çeşitli şirketler bulunmaktadır. Müşteri memnuniyetini en üst düzeye çıkarmak için 
                    müşteri ihtiyaçlarına özel çözümler geliştirir ve müşterilerimizin iş süreçlerini kolaylaştırmak için 
                    en yenilikçi teknolojilere sahip çözümler sunar.
                    </p>
                    <p>
                      Mottomuz '<strong>Her gün yeni bir şey öğrenmek</strong>'tir.
                    </p>
                  </Tab.Pane>
                  <Tab.Pane eventKey="second">
                    <p>
                      Rise of Software yazılım şirketi, yazılıma gönül vermiş, yazılım alanında kendini geliştirmiş
                      kadrosuyla dünyaya açılmayı, tüm çalışanlarını çalışma ortamından keyif almalarını,
                      müşterilerine en kaliteli hizmeti vermeyi hedeflemektedir.
                    </p>
                    <p>
                    Rise Of Software yazılım şirketi, müşterilerimize en iyi kalitede hizmetler sunarak, sektöründe 
                    lider bir konuma ulaşmak ve müşteri memnuniyetini en üst düzeye çıkarmayı hedefler. 
                    Gelecekte yazılım çözümlerimizle müşterilerimizin işlerini kolaylaştırmak ve onların hedeflerine 
                    ulaşmalarını sağlamak için sürekli olarak yenilikçi çözümler geliştirmeyi amaçlıyoruz. Ayrıca 
                    çalışanlarımızın yeteneklerini ve motivasyonlarını artırmak için özverili bir şekilde çalışıyor 
                    ve onların profesyonel gelişimine yatırım yapıyoruz. Bu sayede müşterilerimizin ve çalışanlarımızın 
                    memnuniyetini maksimize ederek, sektörde uzun vadeli bir başarı elde etmeyi hedefliyoruz.
                    </p>
                  </Tab.Pane>
                  <Tab.Pane eventKey="third">
                      <p>
                        Rise of Software yazılım şirketi, geliştirmekte olduğu projeleri müşterilerine
                        dünya standartlarında kaliteli bir hizmet sunulmasını hedefler. 
                      </p>
                      <p>
                        Rise of Sofware yazılım şirketi, dünyadaki yazılım gelişmelerini yakından takip
                        ederek sağlamış olduğu kaliteli hizmetin güncel ve sürekli olmasını hedefler.
                      </p>
                      <p>
                        Müşterilerinin projelerini kendi projeleri gibi benimser, temiz ve SEO uyumlu 
                        kod yazmaya özen gösterir.
                      </p>
                      <p>
                        Rise of Software yazılım şirketinin en önemli misyonu müşteri memnuniyetidir.
                      </p>
                  </Tab.Pane>
                </Tab.Content>
                </Tab.Container>
              </div>}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default About