import { Col } from "react-bootstrap"
import './style.css'

export const Card1 = ({ title, description, imgUrl, href }) => {
    return (
      <Col size={12} sm={6} md={4}>
        <a href={href} target="_blank" >
          <div className="card1-imgbx">
          <img src={imgUrl} alt="#" />
          <div className="card1-txtx">
            <h4>{title}</h4>
            <p  className="description">{description}</p>
          </div>
        </div>
        </a>
      </Col>
    )
  }