import React, { useEffect, useState } from 'react'
import './style.css';
import { Container, Row, Col, Card } from 'react-bootstrap';
import { BsArrowRightCircle, BsCalendarCheck, BsCheckCircle } from 'react-icons/bs'
import code from '../../assets/code.webp'
import { Link } from 'react-router-dom';
import Cards from '../Cads/Card';


const Banner = () => {
    const [loopNum, setLoopNum] = useState(0);
    const [isDeleting, setIsDeleting] = useState(false);
    const toRotate = ["Yazılım Eğitimleri", "Web Hizmetleri" , "Sosyal Medya Hizmetleri" ];
    const [text, setText] = useState('');
    const [delta, setDelta] = useState(300 - Math.random() * 100);
    const period = 2000;

    useEffect(() => {
        let ticker = setInterval(() => {
            tick();
        }, delta)

        return () => { clearInterval(ticker)};
    }, [text])

    const tick = () => {
        let i = loopNum % toRotate.length;
        let fullText = toRotate[i];
        let updatedText = isDeleting ? fullText.substring(0, text.length -1) : fullText.substring(0, text.length +1);
        
        setText(updatedText);

        if (isDeleting) {
            setDelta(prevDelta => prevDelta /2)
        }

        if (!isDeleting && updatedText === fullText) {
            setIsDeleting(true);
            setDelta(period);
        } else if(isDeleting && updatedText === ''){
            setIsDeleting(false);
            setLoopNum(loopNum +1);
            setDelta(500);
        }
    }

  return (
    <section className='banner' >
        <Container>
            <Row className='align-items-center'>
                <Col xs={12} md={6} xl={7}>
                    <span className='tagline'>Rise Of Software'ye Hoşgeldiniz</span>
                    <h1>{'Rise Of Software ekibimiz'} <br/> <span>{'sizin için özenle websitesi hazırlar. Bunun ile birlikte size eğitim vererek kendi websitenizi yapabilme seviyesine gelmenizi sağlar.'}</span>  <br /> <span className='wrap'>{text}</span> </h1>
                    <p>Hızlı, Güvenilir, Kaliteden ödün vermeyen ekip</p>
                    <button onClick={() => console.log('connect')}>
                       <Link className='connect' to='/services'>Hizmetlerimize Bir Göz Atın <BsArrowRightCircle size={25} /> </Link> 
                    </button>
                    <br/>
                    <button onClick={() => console.log('connect')}> 
                       <Link className='connect' to='/egitim'>Eğitimlerimize Bir Göz Atın <BsArrowRightCircle size={25} /> </Link>
                    </button>
                </Col>
                <Col xs={12} md={6} xl={4}>
                    <div className='text-center'>
                        <img src={code} alt='' />
                    </div>
                    
                </Col>
            </Row>
        </Container>
        <Container>
            <Row>
                <div xs={12} md={6} xl={7}>
                   <h1 className='egitim'>Yaklaşan Eğitim Tarihleri</h1>
                   <div className='text-center'>
                    <h4> <BsCalendarCheck size={28} /> &nbsp; 04.09.2023 ~ Front-end Eğitimi  &nbsp;
                    <button onClick={() => console.log('connect')}>
                        <Link className='connect' to='/frontend'> <BsCheckCircle size={28} /> &nbsp; Eğitim müfredatı için tıklayınız <BsArrowRightCircle size={25} /> </Link> 
                     </button> 
                    </h4>
                    <h4 className='mt-5'> <BsCalendarCheck size={28} /> &nbsp; 04.09.2023 ~ Yazılım Uzmanlığı Eğitimi  &nbsp;
                    <button onClick={() => console.log('connect')}>
                        <Link className='connect' to='/fullstack'> <BsCheckCircle size={28} /> &nbsp; Eğitim müfredatı için tıklayınız <BsArrowRightCircle size={25} /> </Link> 
                     </button> 
                    </h4>
                    <h4 className='mt-5'> <BsCalendarCheck size={28} /> &nbsp; 04.03.2024 ~ Back-end Eğitimi  &nbsp;
                    <button onClick={() => console.log('connect')}>
                        <Link className='connect' to='/backend'> <BsCheckCircle size={28} /> &nbsp; Eğitim müfredatı için tıklayınız <BsArrowRightCircle size={25} /> </Link> 
                     </button> 
                    </h4>
                   </div>
                </div>
            </Row>
        </Container>
        <Container>
            <Cards />
        </Container>
    </section>
  )
}

export default Banner