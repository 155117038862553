import React from 'react'
import { Col, Container, Row, Tab } from 'react-bootstrap';
import { Card1 } from './card';
import './style.css'
import Img1 from '../../assets/demotemplate1.webp'
import Img2 from '../../assets/demotemplate2.webp'
import Img3 from '../../assets/ecommerce.webp'
import TrackVisibility from 'react-on-screen';
import 'animate.css';

const Services = () => {
  const services = [
    {
      title: "Portfolyo Sitesi",
      description: "detaylar için tıklayın",
      imgUrl: Img1,
      href: "https://silly-sawine-93820a.netlify.app/"
    },
    {
      title: "Kişisel Web Sitesi",
      description: "detaylar için tıklayın",
      imgUrl: Img2,
      href:"https://visionary-scone-8d7635.netlify.app/" ,
    },
    {
      title: "E-Ticaret Sitesi",
      description: "detaylar için tıklayın",
      imgUrl: Img3,
      href:"https://helenintasmagzasi.com/" ,
    },
  ];

  return (
    <section className='services' id='services'>
      <Container>
        <Row>
          <Col size={12}>
            <TrackVisibility>
              {({ isVisible }) =>
              <div className={isVisible ? "animate__animated animate__fadeIn" : ""}>
                <h2>Hizmetlerimiz</h2>
                <Tab.Container id='services-tabs' defaultActiveKey='first'>
                <Tab.Content id="slideInUp" className={isVisible ? "animate__animated animate__slideInUp" : ""}>
                  <Tab.Pane eventKey='first'>
                    <Row>
                      {
                        services.map((servis, index) => {
                          return (
                            <Card1 
                              key={index}
                              {...servis} />
                          )
                        })
                      }
                    </Row>
                  </Tab.Pane>
                </Tab.Content>
                </Tab.Container>
              </div>}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default Services