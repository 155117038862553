import React, { Component } from 'react'
import Card from './CardU';
import img1 from '../../assets/giphy.webp'
import img2 from '../../assets/giphy1.webp'
import img3 from '../../assets/giphy2.webp'


class Cards extends Component {
    render() {
        return (
            <div className='container-fluid d-flex justify-content-center'>
                <div className='row m-auto'>
                    <h1 className='text-center mb-5'>NEDEN BİZ?</h1>
                    <div className='col-lg-4 md-12'>
                        <Card imgsrc={img1} title='Müşteri Memnuniyeti' text='Müşteri memnuniyeti, işimizin temel noktasıdır. Sizin isteklerinizi ve beklentilerinizi anlamak ve bunları karşılamak için kendimizi sürekli olarak geliştirmekteyiz. Sizlerle olan iletişimimizde samimiyet, güvenilirlik ve özen en önemli prensiplerimizdir.' />
                    </div>
                    <div className='col-lg-4 md-12'>
                        <Card imgsrc={img2} title='Modern Teknoloji' text='Hızla ilerleyen teknoloji, sizin ihtiyaçlarınıza uygun çözümler sunmak için sürekli olarak kendimizi güncellemekteyiz. Sektördeki yenilikleri yakından takip ediyor ve en son teknolojik trendleri ürün ve hizmetlerimize entegre ediyoruz.' />
                    </div>
                    <div className='col-lg-4 md-12'>
                        <Card imgsrc={img3} title='Enerjik ve Yaratıcı Ekip' text='Sizlerle olan işbirliğimizde, enerjik ve yaratıcı ekibimiz sizinle aktif iletişim kurarak fikirlerinizi dinleyerek ve projelerinizi en iyi şekilde yönetmek için özveriyle çalışıyoruz. Sizin başarınız ve memnuniyetiniz, bizim en büyük motivasyon kaynağımızdır.' />
                    </div>
                </div>
              
            </div>
          )
    }
}

export default Cards;
